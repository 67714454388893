<template>
  <b-modal
    id="product-add-sub-product"
    title="Yeni Alt Ürün Ekleme"
    size="lg"
    ok-only
    ok-title="Kaydet"
  >
    <!--
    <b-row>
      <b-col cols="2">
        <b-form-input v-model="filter.product.id" placeholder="Ürün ID"/>
      </b-col>
      <b-col cols="2">
        <b-form-input v-model="filter.product.barcode" placeholder="Ürün Barkod"/>
      </b-col>
      <b-col cols="2">
        <b-form-input v-model="filter.product.name" placeholder="Ürün Adı"/>
      </b-col>
      <b-col cols="2">
        <b-form-input id="basicInput" placeholder="Marka"/>
      </b-col>
      <b-col cols="2">
        <b-button @click="getProducts">Getir</b-button>
      </b-col>
    </b-row>
    -->
    <b-row>
      <b-col cols="12">
        <b-row v-for="(bundleProduct,index) in bundleProducts" :key="index">
          <b-col cols="4">
            <b-form-input type="number" v-model="bundleProduct.bundle_product_id" placeholder="Ürün ID"/>
          </b-col>
          <b-col cols="4">
            <b-form-input type="number" v-model="bundleProduct.multiple" placeholder="Kat Sayı (Çarpan)"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-button variant="primary" @click="addSub">Satır Ekle</b-button>
        <b-button variant="success" @click="saveSub">Ürünleri Kaydet</b-button>
      </b-col>
    </b-row>

  </b-modal>
</template>

<script>
import axios from '@/libs/axios'

export default {
  name: 'AddSubProductModal',
  data: () => ({
    productUuid: null,
    bundleProducts: [
      {
        bundle_product_id: '',
        multiple: 1,
      },
    ],
    filter: {
      product: {
        id: '',
        barcode: '',
        name: '',
        brandIds: [],
      },
    },
  }),
  methods: {
    addSub() {
      this.bundleProducts.push({
        bundle_product_id: '',
        multiple: 1,
      })
    },
    saveSub() {
      let isNull = false
      this.bundleProducts.forEach(item => {
        if (item.bundle_product_id == '' || item.multiple == '') isNull = true
      })
      if (isNull) {
        this.$swal({
          icon: 'warning',
          title: 'Veriler Boş Olamaz',
        })
      }
      axios.post('/product/bundle/' + this.productUuid, this.bundleProducts)
        .then(res => {
          if (res.data.status == 'success') {
            console.log(res.data.content);
            this.$store.commit('product/setProductBundles', res.data.content)
            this.$swal({
              icon: 'success',
              title: 'Kayıt Başarılı',
            })
          } else {
            this.$swal({
              icon: 'errorr',
              title: res.data.message,
            })
          }
        })
    },
  },
  mounted() {
    this.$root.$on('add-sub-product-product-id', (data) => {
      this.productUuid = data
    })
  },
}
</script>

<style scoped>

</style>
