<template>
  <div>

    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="PackageIcon" size="19"/>
      <h4 class="mb-0 ml-50"> Ürün Bilgileri ({{ _product.id }})</h4>
    </div>
    <b-overlay :show="isShow">
      <b-form class="mt-1">
        <b-row>
          <!-- Field: Birth Date -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Ürün Kodu" label-for="product-code">
              <b-form-input type="text" id="product-code" v-model="_product.product_uuid" disabled/>
            </b-form-group>
          </b-col>

          <!-- Field: Birth Date -->

          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Ürün Kısa Adı" label-for="product-name">
              <b-form-input type="text" id="product-name" v-model="_product.short_name"/>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Ürün Adı" label-for="product-name">
              <b-form-input type="text" id="product-name" v-model="_product.name"/>
            </b-form-group>
          </b-col>

          <!-- Field: Birth Date -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Ürün Barkod" label-for="product-barcode">
              <b-form-input type="text" id="product-barcode" v-model="_product.barcode"/>
            </b-form-group>
          </b-col>

          <b-col cols="10" md="5" lg="3">
            <b-form-group label="Marka" label-for="brand">
              <v-select
                  v-model="_product.brand_id"
                  :options="_productBrands"
                  label="name"
                  :reduce="item => item.id"
                  input-id="brand"
              />
            </b-form-group>
          </b-col>

          <b-col cols="2" md="1" lg="1">
            <b-form-group label="Marka" label-for="brand">
              <b-button variant="success" @click="addProductBrand">Ekle</b-button>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Durum" label-for="status">
              <v-select
                  v-model="_product.product_status_id"
                  :options="_productStatus"
                  label="name"
                  :reduce="item => item.id"
                  input-id="status"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <b-form-group label="KDV" label-for="vat_rate">
              <v-select
                  v-model="_product.vat_rate"
                  :options="_vatRates"
                  :reduce="item => item.value"
                  input-id="vat_rate"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Stok Adedi" label-for="product-quantity">
              <b-form-input type="number" id="product-quantity" v-model="_product.quantity"
                            :disabled="_product.isBundle?true:false"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Teslimat Süresi" label-for="product-deadline">
              <b-form-input type="number" id="product-deadline" v-model="_product.deadline"
                            :disabled="_product.isBundle?true:false"
              />
            </b-form-group>
          </b-col>


          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Ürün Alış fiyatı" label-for="product-purchase_price">
              <b-form-input type="number" id="product-purchase_price" v-model="_product.purchase_price"
                            :disabled="_product.isBundle?true:false"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Ürün Gramı" label-for="product-weight">
              <b-form-input type="number" id="product-barcode" v-model="_product.weight"
                            :disabled="_product.isBundle?true:false"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <b-form-group
                label="Ürün Tipi"
                label-for="product-type"
                label-class="mb-1"
            >
              <b-form-radio-group
                  id="product-type"
                  v-model="_product.isBundle"
                  :options="[{text:'Ürün',value:'0'},{ text:'Paket',value:'1'}]"
                  :disabled="_product.id?true:false"
              />
            </b-form-group>
          </b-col>

        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-button
                v-if="_product.id"
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                @click="productUpdate"
            >
              Güncelle
            </b-button>
            <b-button
                v-else
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                @click="productSave"
            >
              Kaydet
            </b-button>
            <b-button
                variant="outline-secondary"
                @click="productCancel"
            >
              İptal Et
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </div>
</template>

<script>

import vSelect from 'vue-select'
import {mapGetters} from 'vuex'

export default {
  name: 'ProductEditHome',
  components: {vSelect},
  data: () => ({
    isShow: false,
  }),
  computed: {
    ...mapGetters('product', ['_productBrands', '_productStatus', "_vatRates"]),
  },
  props: {_product: Object},
  methods: {
    productSave() {
      this.isShow = true
      this.$store.dispatch('product/saveProduct', this._product)
          .then(res => {
            if (res.content) {
              this.$store.commit('product/setProduct', res.content)
              //this._product = res.content
            }
            this.isShow = false
          })
    },
    productUpdate() {
      this.isShow = true
      this.$store.dispatch('product/updateProduct', this._product)
          .then(res => {
            this.isShow = false
          })
    },
    productCancel() {
      this.$router.go(-1)
    },
    addProductBrand() {
      this.$swal({
        title: 'Marka Giriniz',
        input: 'text',
        showCancelButton: true,
        cancelButtonText: 'Vazgeç',
        confirmButtonText: "Kaydet",
        showLoaderOnConfirm: true,
        preConfirm: async (brandName) => {
          return this.$store.dispatch('product/storeProductBrand', {name: brandName})
              .then(res => {
                return res;
              })
        },
      }).then((result) => {
        this.$swal({
          title: 'Kayıt Başarılı',
          icon: 'success',
        });
      });
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
