<template>
  <component :is="_product === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="_product === undefined">
      <h4 class="alert-heading"> Ürün Bulunamadı... </h4>
      <b-link class="alert-link" :to="{ name: 'product-product'}">
        Ürün Listesinden
      </b-link>
    </b-alert>

    <b-tabs v-if="_product" pills>

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="PackageIcon" size="16" class="mr-0 mr-sm-50"/>
          <span class="d-none d-sm-inline">Ürün Detayları</span>
        </template>
        <ProductEdit :_product="_product" class="mt-2 pt-75"/>
      </b-tab>

      <!-- Tab: Information -->
      <b-tab :disabled="!_product.id">
        <template #title>
          <feather-icon icon="ImageIcon" size="16" class="mr-0 mr-sm-50"/>
          <span class="d-none d-sm-inline">Ürün Resimleri</span>
        </template>
        <ImageEdit :images="_product.images" :product_uuid="_product.product_uuid" class="mt-2 pt-75"/>
      </b-tab>

      <!-- Tab: Social -->
      <b-tab :disabled="_product.isBundle!=1">
        <template #title>
          <feather-icon icon="CodesandboxIcon" size="16" class="mr-0 mr-sm-50"/>
          <span class="d-none d-sm-inline">Paket Ürünler</span>
        </template>
        <BundleEdit
          :bundleProducts="_product.bundle_products"
          :productUuid="_product.product_uuid"
        />
      </b-tab>
    </b-tabs>
    <AddSubProductModal/>
  </component>

</template>

<script>
import ProductEdit from '@/views/product/edit/ProductEdit.vue'
import ImageEdit from '@/views/product/edit/ImageEdit.vue'
import BundleEdit from '@/views/product/edit/BundleEdit'
import AddSubProductModal from '@/views/product/edit/AddSubProductModal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ProductEditHome',
  components: {
    ProductEdit,
    ImageEdit,
    BundleEdit,
    AddSubProductModal
  },
  computed: {
    ...mapGetters('product', ['_product']),
    disabledImages() {
      if (this._product.images) {
        if (this._product.images.length > 0) return false
      }
      return true
    },
  },
  mounted() {
    this.$store.commit('product/setProduct', {})
    if (this.$router.currentRoute.params.id) {
      this.$store.dispatch('product/getProduct', this.$router.currentRoute.params.id)
    }
    this.$store.dispatch('product/getProductBrands')
    this.$store.dispatch('product/getProductStatus')
  },
}
</script>

<style scoped>

</style>
