<template>
  <div>
    <div class="d-flex">
      <feather-icon icon="ImageIcon" size="19"/>
      <h4 class="mb-0 ml-50"> Ürün Resimleri </h4>
    </div>

    <b-media class="mb-2">
      <template #aside>
        <div class="clearfix">
          <b-img
            v-for="(image,index) in selectedImages"
            :key="index"
            left
            class="mt-1 mb-1 mr-1"
            height="120"
            :src="image"
          />
        </div>
      </template>
    </b-media>
    <hr>
    <b-media class="mb-2">
      <template #aside>
        <div class="clearfix">
          <b-img
            v-for="(image,index) in images"
            :key="index"
            left
            class="mt-1 mb-1 mr-1"
            height="120"
            :src="siteUrl+image.url"
          />
        </div>
      </template>
    </b-media>
    <hr>
    <b-row class="mt-2">
      <b-col>
        <input ref="refInputEl" type="file" class="d-none" @input="inputImageRenderer" multiple>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="$refs.refInputEl.click()"
        >
          Resim Ekle
        </b-button>

        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="imageSave"
          :disabled="selectedImages.length<1?true:false"
        >
          Kaydet
        </b-button>
        <b-button
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          variant="outline-secondary"
          @click="imageCancel"
        >
          İptal Et
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'ImageEdit',
  props: {
    images: Array,
    product_uuid: String,
  },
  data: () => ({
    selectedImages: [],
  }),
  computed: {
    siteUrl() {
      return process.env.VUE_APP_IMG_URL
    },
  },
  methods: {
    inputImageRenderer(e) {
      e.target.files.forEach(image => {
        this.selectedImages.push(URL.createObjectURL(image))
      })
    },
    imageSave() {
      this.$store.dispatch('product/storeProductImages', {
        product_uuid: this.product_uuid,
        images: this.$refs.refInputEl.files,
      })
        .then(res => {
          if (res.status == 'success') {
            this.$swal({
              icon: 'success',
              title: 'Kayıt Başarılı',
            })
          } else {
            this.$swal({
              icon: 'error',
              title: 'Kayıt Başarısız',
            })
          }
        })
    },
    imageCancel() {
      this.selectedImages = []
    },
  },
}
</script>

<style scoped>

</style>
