<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="CodesandboxIcon" size="19"/>
      <h4 class="mb-0 ml-50"> Paket Bilgileri </h4>
      <b-button id="addNewProduct" class="pull-right" variant="success" size="sm" @click="addNewProduct">Ürün Ekle
      </b-button>
    </div>
    <b-row>
      <b-col cols="12">
        <ProductTable :products="bundleProducts"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ProductTable from '@/views/product/product/ProductTable.vue'

export default {
  name: 'BundleEdit',
  components: { ProductTable },
  props: {
    productUuid: {
      type: String,
    },
    bundleProducts: {
      type: Array,
      default: null,
    },
  },
  methods: {
    addNewProduct() {
      this.$root.$emit('add-sub-product-product-id', this.productUuid)
      this.$root.$emit('bv::show::modal', 'product-add-sub-product', '#addNewProduct')
    },
  },
}
</script>

<style scoped>

</style>
